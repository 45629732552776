<template>
  <div>
    <b-tabs
      pills
      :vertical="$store.getters['app/currentBreakPoint'] === 'xs'"
      content-class="col-12 px-sm-0"
      nav-wrapper-class="col-12 px-sm-0"
      nav-class="nav-left mb-sm-2"
    >
      <b-tab v-for="tab in tabs" :key="tab.key" :title="tab.name" lazy>
        <table-dynamic-server-side :key-name="tab.key" :url="tab.url" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  components: {
    TableDynamicServerSide: () => import('./components/TableDynamicServerSide.vue'),
  },
  data() {
    return {
      tabs: [
        {
          key: 'all',
          name: 'ทั้งหมด',
          url: 'api/admin/affiliate/index',
        },
        {
          key: 'pending',
          name: 'รออนุมัติเอกสารยืนยันตัวตน',
          url: 'api/admin/affiliate/index?f_status=Submit',
        },
        {
          key: 'approve',
          name: 'อนุมัติเอกสารยืนยันตัวตน',
          url: 'api/admin/affiliate/index?f_status=Approval',
        },
        {
          key: 'reject',
          name: 'ไม่อนุมัติเอกสารยืนยันตัวตน',
          url: 'api/admin/affiliate/index?f_status=Reject',
        },
        {
          key: 'ban',
          name: 'แบนบัญชี',
          url: 'api/admin/banned/index',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped></style>
